<template>
	<div class="CSpecimen" :style="{ borderColor: isPadrillo ? 'var(--gray)' : 'var(--gray)' }">
		<div v-if="isPadrillo && check_padrillo" class="check-padrillo">
			<input
				class="custom__checkbox"
				type="checkbox"
				v-model="check_padrillo.checked"
				@input="changeCheckPadrillo"
			/>
		</div>
		<img
			class="CSpecimen__image"
			:src="specimen.thumb ? specimen.thumb : '/rooster/rooster.jpg'"
			onerror="this.src='/rooster/rooster.jpg'"
		/>
		<!-- {{ specimen.last_insemination }} -->

		<div class="CSpecimen__info">
			<span class="CSpecimen__plate" @click="linkSpecimen(specimen.id)">{{ specimen.plate }}</span>
			<span class="CSpecimen__alias">{{ specimen.alias || "Sin alias" }}</span>
			<span
				class="CSpecimen__alias mt-50 d-flex"
				v-if="!isPadrillo && specimen.last_insemination"
				v-b-tooltip.hover.top="`Ultima Inseminacion`"
			>
				<svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 469.333 469.333">
					<path
						d="M466.204 87.792 381.417 3.125c-4.173-4.167-10.932-4.167-15.105 0a10.65 10.65 0 0 0 0 15.083L379.122 31l-70.35 70.25-44.855-44.792c-4.173-4.167-10.932-4.167-15.105 0a10.65 10.65 0 0 0 0 15.083L273.305 96 67.22 301.792a10.65 10.65 0 0 0-3.129 7.542c0 22.622 8.202 43.164 21.358 59.589l-82.32 82.203a10.65 10.65 0 0 0 0 15.083 10.655 10.655 0 0 0 7.552 3.125c2.733 0 5.466-1.042 7.552-3.125l82.32-82.203c16.448 13.138 37.019 21.328 59.673 21.328a10.68 10.68 0 0 0 7.552-3.125l206.084-205.792 24.493 24.458c2.086 2.083 4.819 3.125 7.552 3.125s5.466-1.042 7.552-3.125a10.65 10.65 0 0 0 0-15.083l-46.524-46.458 70.35-70.25 13.811 13.792c2.086 2.083 4.819 3.125 7.552 3.125s5.466-1.042 7.552-3.125a10.65 10.65 0 0 0 .004-15.084zm-330.662 239.75a10.634 10.634 0 0 1-7.542 3.125 10.636 10.636 0 0 1-7.542-3.125l-19.115-19.115c-4.167-4.167-4.167-10.917 0-15.083 4.167-4.167 10.917-4.167 15.083 0l19.115 19.115c4.167 4.166 4.167 10.916.001 15.083zm32-53.334a10.634 10.634 0 0 1-7.542 3.125 10.636 10.636 0 0 1-7.542-3.125l-8.448-8.448c-4.167-4.167-4.167-10.917 0-15.083 4.167-4.167 10.917-4.167 15.083 0l8.448 8.448c4.167 4.167 4.167 10.917.001 15.083zm53.333-32a10.634 10.634 0 0 1-7.542 3.125 10.636 10.636 0 0 1-7.542-3.125l-19.115-19.115c-4.167-4.167-4.167-10.917 0-15.083 4.167-4.167 10.917-4.167 15.083 0l19.115 19.115c4.168 4.167 4.168 10.917.001 15.083zm32-53.333a10.634 10.634 0 0 1-7.542 3.125 10.636 10.636 0 0 1-7.542-3.125l-8.448-8.448c-4.167-4.167-4.167-10.917 0-15.083 4.167-4.167 10.917-4.167 15.083 0l8.448 8.448c4.168 4.166 4.168 10.916.001 15.083z"
						fill="var(--primary)"
					></path>
				</svg>
				<p class="ml-25 mb-0" v-if="specimen.last_insemination">
					{{ specimen.last_insemination | myGlobalDayShort }}
				</p>
			</span>
			<span
				class="CSpecimen__alias mt-50 d-flex"
				v-if="isPadrillo && specimen.last_insemination"
				v-b-tooltip.hover.top="`Ultima Extraccion`"
			>
				<v-icon name="gi-eyedropper" scale="1" class="text-primary" />

				<p class="ml-25 mb-0">{{ specimen.last_insemination | myGlobalDayShort }}</p>
				
				<div v-if="specimen.postures" class="ml-auto d-flex align-items-center">
					<img src="/encaste/hens.png" style="width:16px"/>
					<!-- <p class="mb-0 ml-25 font-weight-bolder">{{ specimen.leftover_madrillas }}</p> -->
					<span class="font-weight-bolder ml-25">{{ specimen.postures }}</span>
				</div>
			</span>
			<label class="CSpecimen__label" v-if="!hideSelect && !isPadrillo">
				<input class="CSpecimen__checkbox" type="checkbox" v-model="specimen.inseminated" />
				<span>Inseminada</span>
			</label>
		</div>
		<div class="CSpecimen__actions">
			<button
				class="CSpecimen__action"
				v-b-tooltip.hover.top="`Ver Historial`"
				@click="showTracking"
				v-if="visible_eye"
			>
				<feather-icon icon="EyeIcon" size="20" />
			</button>
			<div v-else-if="specimen.leftover_madrillas" class="d-flex align-items-center">
				<!-- <feather-icon icon="DropletIcon" size="18" /> -->
				<img src="/encaste/hen.png" style="transform: scale(0.6) translateX(10px)" />
				<p class="mb-0 ml-25 font-weight-bolder">{{ specimen.leftover_madrillas }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import fertilityService from "../fertilidad.service"

import SlidersImageVideo from "@/views/amg/header-bookmarks/SlidersImageVideo.vue"

export default {
	name: "NewCardSpecimen",
	components: {
		SlidersImageVideo,
	},
	props: {
		specimen: Object,
		hideSelect: {
			type: Boolean,
			default: false,
		},
		check_padrillo: {
			type: Object,
		},
		tab: {
			type: Number,
		},
		visible_eye: {
			type: Boolean,
			default: false,
		},
		index: {
			type: Number,
		},
	},
	data() {
		return {
			isLoaded: false,
			imageSpecimen: {
				url: null,
				type: null,
				text: null,
				text_url: null,
				open: false,
				specimen_id: null,
			},
		}
	},
	computed: {
		isPadrillo() {
			return this.specimen.category_id == 1
		},
		bottomBorder() {
			if (this.isPadrillo) return "border-bottom:.5rem solid var(--primary)"
			return "border-bottom:.5rem solid var(--secondary)"
		},
	},
	methods: {
		linkSpecimen(id) {
			window.open(`/ejemplares/detalle/${id}`, "_blank")
		},
		refresh() {
			this.imageSpecimen.open = false
			this.imageSpecimen.text_url = null
			this.imageSpecimen.type = null
			this.imageSpecimen.text = null
			this.imageSpecimen.url = null
			this.imageSpecimen.specimen_id = null
		},
		showImageSpecimen(item) {
			this.imageSpecimen.open = true
			this.imageSpecimen.text_url = `detalle/${item.id}`
			this.imageSpecimen.type = "image"
			this.imageSpecimen.text = `${item.plate} ${item.alias ? "-" : ""} ${item.alias ? item.alias : ""}`
			this.imageSpecimen.url = item.image
			this.imageSpecimen.specimen_id = item.id
		},
		async markAsInsemiated({ madrilla_id, padrillo_id, postura_id, status_id }) {
			this.isPreloading()
			const { message, status } = await fertilityService.markAsInseminated({
				madrilla_id,
				padrillo_id,
				postura_id,
				status_id,
			})

			if (status) {
				this.showToast("success", "top-right", "Inseminación", "SuccessIcon", message)
			}

			this.isPreloading(false)
		},
		showTracking() {
			// if (!this.specimen.insemination.last_date) {
			// 	return this.showToast(
			// 		"warning",
			// 		"top-right",
			// 		"Inseminación",
			// 		"WarningIcon",
			// 		"No existe inseminaciones."
			// 	);
			// }

			// console.log("sp", this.specimen.insemination.last_date);

			if (this.isPadrillo) {
				this.$emit("tracking-padrillo", {
					postura_id: this.specimen.postura_id,
					title: `Historial / ${this.specimen.alias ? this.specimen.alias : ""} (${this.specimen.plate}) `,
					padrillo: this.specimen,
				})
			} else {
				this.$emit("tracking-madrilla", {
					postura_id: this.specimen.postura_id,
					title: `Historial / ${this.specimen.alias ? this.specimen.alias : ""} (${this.specimen.plate}) `,
					madrilla: this.specimen,
				})
			}
		},

		changeCheckPadrillo(val) {
			const isChecked = val.target.checked
			if (isChecked) this.$emit("addPadrilloToProgramming", this.index)
			if (!isChecked) this.$emit("removePadrilloToProgramming", this.index)
		},
	},
}
</script>

<style lang="scss" scoped>
.CSpecimen {
	position: relative;
	display: flex;
	align-items: center;

	// min-height: 10rem;
	padding: 0.4rem;
	// background: var(--white);
	// border-radius: 1rem !important;
	// background: red;
	// border-bottom: 0.5rem solid;
	height: 100% !important;
	width: 100% !important;
	// background: red;

	@media screen and (min-width: 900px) {
		// gap: 1rem;
	}

	&__image {
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		cursor: default;
		@media screen and (min-width: 900px) {
			width: 4rem;
			height: 4rem;
		}
	}
	&__info {
		flex: 1;
		margin-left: 0.5rem;
		min-width: 90px;
		@media screen and (min-width: 900px) {
			min-width: 120px;
		}
	}

	&__plate {
		display: inline-block;
		color: var(--primary);
		font-size: 1rem;
		font-weight: 600;
		line-height: 1;
		cursor: pointer;
		@media screen and (min-width: 900px) {
			font-size: 1.1rem;
		}
	}

	&__alias {
		display: block;
		// margin-bottom: 0.2rem;
		color: var(--secondary);
		font-size: 0.8rem;
		@media screen and (min-width: 900px) {
			font-size: .9rem;
		}
	}
	&__label {
		display: flex;
		align-items: center;
		color: var(--primary);
		font-size: 1rem;
		cursor: pointer;
		margin-top: 0.5rem;
	}
	&__checkbox {
		margin-right: 0.5rem;
		display: inline-block;
		cursor: pointer;
		border-radius: 0.2rem;
		background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
		width: 1.5rem;
		height: 1.5rem;
		appearance: none;
		border: 2px solid var(--gray);
		background-position: 0 -2rem;
		background-size: 100%;
		background-repeat: no-repeat;
		transition: all 0.2s ease-in-out;

		&:checked {
			background-color: var(--primary);
			color: var(--primary);
			background-position: 0 0;
		}
	}
	&__actions {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		// background: #171631;
		@media screen and (min-width: 900px) {
			// top: .8rem;
			// right: .8rem;
		}
	}
	&__action {
		cursor: pointer;
		background: var(--white);
		color: var(--primary);
		border: none;
		border-radius: 0.2rem;
		outline: none;
		box-shadow: 0 0 4px rgba($color: #000000, $alpha: 0.4);
	}
	&__badge {
		display: inline-block;
		margin-right: 1rem;
		padding: 0.2rem 0.4rem;
		background: var(--primary);
		color: var(--white);
		border-radius: 0.4rem;
		font-weight: 600;
		font-size: 0.8rem;
	}
}
.dark-layout {
	.CSpecimen {
		background: #283046;
		&__plate {
			color: var(--white);
		}
		&__alias {
			color: var(--secondary);
		}
		&__label {
			color: var(--white);
		}
		&__actions {
			background: #171631 !important;
		}
	}
}
.height-inactive-rooster {
	height: 30px;
	width: 30px;
}

.custom__checkbox {
	margin-right: 0rem;
	display: inline-block;
	cursor: pointer;
	border-radius: 0.2rem;
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
	width: 1.2rem;
	height: 1.2rem;
	appearance: none;
	border: 2px solid var(--gray);
	border-radius: 0.5rem;
	background-position: 0 -3rem;
	background-size: 100%;
	background-repeat: no-repeat;
	transition: all 0.2s ease-in-out;

	@media screen and (min-width: 600px) {
		width: 1.7rem;
		height: 1.7rem;
		margin-right: 0.2rem;
	}

	&:checked {
		background-color: var(--primary);
		color: var(--primary);
		background-position: 0 0;
	}
}
.check-padrillo {
	justify-self: flex-start;
	// background: red;
	height: 100%;
}
</style>
